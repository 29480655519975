<template>
  <div id="solutions_education">
    <solutions-header
      class="education-services-header"
      :title="$t('solutions.educationService.title')"
      :subtitle="$t('solutions.educationService.subtitle')"
      divider-variant="education-banner"
      login-variant="education-banner"
    />
    <solutions-content
      :problem-statement="$t('solutions.educationService.problem.statement')"
      :problem-description="$t('solutions.educationService.problem.description')"
      :solution="$t('solutions.educationService.solution')"
      :pipeline="require('@/assets/images/icons/solutions/education/pipeline.svg')"
      :section-img="require('@/assets/images/icons/solutions/education/statement.svg')"
    />
  </div>
</template>

<script>
import SolutionsHeader from '@/views/pages/Solutions/components/SolutionsHeader/Index.vue';
import SolutionsContent from '@/views/pages/Solutions/components/SolutionsContent/Index.vue';

export default {
  name: 'Education',
  components: {
    SolutionsHeader,
    SolutionsContent,
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .education-services-header {
      background: url('~@/assets/images/background/header-corner-top-education.svg') top left no-repeat,
                linear-gradient(299.7deg, #33029B -11.46%, #7323F5 73.4%);

      .content {
        background: url('~@/assets/images/background/solutions-banner-education.svg') right bottom no-repeat;
      }
    }
  }
</style>
